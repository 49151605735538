<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="false"
                :title="'Order Tracker'" @onClickAddNewButton="navigateToAddPage" />
        </div>

        <div class="my-2 px-2">
            <DateQuerySetter btnTitle="Go" @onClickGo="onClickGo">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">PI Order No: </label>
                    <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                        tabindex="1" type="text" placeholder="Please add LC number"
                        v-model="billNumber">
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Customer Name: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>

                    <AsyncSelect placeholder="Select Customer Name" v-model="contactProfile"
                        :api-service="fetchContactProfiles" :additional-query="additionalContactOption"
                        :additional-option="additionalOption" :option-selected="onChangeContact"
                        :key="contactRenderKey" />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Buyer Name: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>

                    <AsyncSelect placeholder="Select Buyer Name" v-model="contactProfile"
                        :api-service="fetchContactProfiles" :additional-query="additionalContactOption"
                        :additional-option="additionalOption" :option-selected="onChangeContact"
                        :key="contactRenderKey" />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-tpp-1">

                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Sales Person: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>

                    <AsyncSelect placeholder="Select Sales Person" v-model="contactProfile"
                        :api-service="fetchContactProfiles" :additional-query="additionalContactOption"
                        :additional-option="additionalOption" :option-selected="onChangeContact"
                        :key="contactRenderKey" />
                </div>
            </DateQuerySetter>
        </div>
        <div class="col-12 px-2 mt-2">
            <ListTable :purchase="purchaseList" route_name="pi-order-edit" :fromApproval="fromApproval" />
        </div>
        <div class="px-2">
            <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination" />
        </div>

        <ContactDropdownFilter v-if="store.state.isModalOpenThree" @onSearchContact="onSearchContact"
            :companyRoles="companyRoles" :isFilterable="true" />
    </div>
</template>

<script setup>
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import { ref, inject, onMounted, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import TitleButton from '@/components/atom/TitleButton'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/inventory/order-management/OrderListTable.vue'
import handleContact from "@/services/modules/contact";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleCrm from '@/services/modules/crm/crm'
import handleRole from "@/services/modules/role";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'

const router = useRouter()
const store = useStore()
let saleType = ref('pi_order')
let offset = ref(20)
let purchaseList = ref([])
let contactProfile = ref(null)
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const singleSR = ref(null);
const billNumber = ref('');
const singleDealer = ref(null);
const dealerRenderKey = ref(0);
const contactRenderKey = ref(0);

const { fetchSalesOrderList, fetchOrderStatus } = handlePurchase()
const { fetchContactProfiles } = handleContact();
const { fetchCompanyDefaultRoles } = handleRole();
const { fetchAllDealers, fetchAllSR } = handleCrm();
const additionalOption = ref([]);
const additionalDealerQuery = ref({});
const additionalContactOption = ref({
    with_mobile: 1,
    dealer_id: '',
    roles: 'customer,lead',
    pi_order_no: null
});
const companyRoles = ref(null);
const additionalDealerOption = ref([]);
const invoiceStatuses = ref([]);
const orderStatuses = ref([]);
const selectedInvoiceStatus = ref(null);
const selectedOrderStatus = ref(null);

const onChangeContact = (e) => {
    //TODO: contact profile id
}

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${companyId.value}&roles=customer,lead`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

const companyId = computed(() => {
    return route.params.companyId
})
const startDate = computed(() => {
    return route.query.start
})
const endDate = computed(() => {
    return route.query.end
})
const page = computed(() => {
    return route.query.page
})
const fromApproval = computed(() => {
    return route.name === 'sales-order-approval';
})

watch(() => singleSR.value, (newValue, oldValue) => {
    additionalDealerQuery.value = {};
    additionalContactOption.value.dealer_id = '';
    singleDealer.value = null;
    contactProfile.value = null;
    dealerRenderKey.value += 1;
    contactRenderKey.value += 1;

    if (newValue) {
        additionalDealerQuery.value = { 'sale_representative_id': newValue.id }
    }
})

watch(() => singleDealer.value, (newValue, oldValue) => {
    additionalContactOption.value.dealer_id = '';
    contactProfile.value = null;
    contactRenderKey.value += 1;

    if (newValue) {
        additionalContactOption.value.dealer_id = newValue.id
    }
})

function navigateToAddPage() {
    const query = Object.assign({}, route.query)
    query.type = 'approval';

    router.push({ name: 'pi-order-add', params: route.params, query: query })
}

function getQuery() {
    let query = '?company_id=' + companyId.value
    if (!startDate.value && !endDate.value) return query
    if (contactProfile.value) query += '&contact_profile_id=' + contactProfile.value.id
    if (startDate.value) query += '&start_date=' + startDate.value
    if (endDate.value) query += '&end_date=' + endDate.value
    if (page.value) query += '&page=' + page.value
    if(billNumber.value) query += '&bill_number='+billNumber.value
    if (selectedOrderStatus.value) query += '&status=' + selectedOrderStatus.value
    if (selectedInvoiceStatus.value) query += '&invoice_status=' + selectedInvoiceStatus.value
    query += '&sale_type=' + saleType.value
    query += '&offset=' + offset.value
    return query
}

function onClickGo() {
    fetchSalesOrderList(getQuery())
        .then(res => {
            if (res.status) {
                purchaseList.value = res.date.data
                setPagination(res.date)
            } else {
                purchaseList.value = []
                store.commit("resetPagination")
            }

        })
}

const getOrderStatus = async () => {
    const res = await fetchOrderStatus('?company_id=' + companyId.value);
    if (res.status) {
        invoiceStatuses.value = res.data.filter(item => ['created', 'pending'].includes(item.value))
        orderStatuses.value = res.data.filter(item => ['pending', 'approved'].includes(item.value))
    }
}

function setPagination(data) {
    if (!data.data.length) return
    store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

function onPageChange(page) {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({ path: route.path, query: routeQuery })
}

onMounted(() => {
    onClickGo()
    getOrderStatus()
    getCompanyRoles()
})

</script>

<style scoped>
.mt-tpp-1 {
    margin-top: 1%;
}

.filter-label {
    padding-right: 0.5rem !important;
}
</style>
